@use '~@angular/material' as mat;

$custom-typography: mat.define-typography-config(
  $font-family: 'Montserrat'
);

@include mat.core($custom-typography);

@import 'theme-reset';
@import 'create-custom-theme';
.app{
    &.green{
        @import "skins/green";
        @include mat.all-component-colors($green-theme);
        @include theme-reset($green-theme);
    }
    &.blue{
        @import "skins/blue";
        @include mat.all-component-colors($blue-theme);
        @include theme-reset($blue-theme);
    }
    &.red{
        @import "skins/red";
        @include mat.all-component-colors($red-theme);
        @include theme-reset($red-theme);
    }
    &.pink{
        @import "skins/pink";
        @include mat.all-component-colors($pink-theme);
        @include theme-reset($pink-theme);
    }
    &.purple{
        @import "skins/purple";
        @include mat.all-component-colors($purple-theme);
        @include theme-reset($purple-theme);
    }
    &.grey{
        @import "skins/grey";
        @include mat.all-component-colors($grey-theme);
        @include theme-reset($grey-theme);
    }
    &.orange-dark{
        @import "skins/orange-dark";
        @include mat.all-component-colors($orange-dark-theme);
        @include theme-reset($orange-dark-theme);     
    }
    &.custom{
        @import "skins/custom";
        @include mat.all-component-colors($custom-theme);
        @include theme-reset($custom-theme);   
    }
}